import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"

import "normalize.css"
import { GlobalProvider } from "./src/context/global-context"

const styleTheme = {
  colors: {
    ci: "#eeff22",
  },
}

const gridConf = {
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
  container: {
    xs: "full",
    sm: 540 / 16,
    md: 720 / 16,
    lg: 960 / 16,
    xl: 1140 / 16,
  },
  breakpoints: {
    xs: 1,
    sm: 36,
    md: 48,
    lg: 62,
    xl: 75,
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    line-height: 1.7;
    font-family: 'Montserrat', sans-serif;
    font-display: swap;
  }

  html, body {
    font-size: 14px;
  }

  p, ul, ol {
    font-size: 1rem;
    font-weight: 500;
    text-align: justify;
    margin-top: 0;
    margin-bottom: 20px;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
    color: #000;
    position: relative;
    text-decoration-color: #000;
    text-decoration-thickness: 1.5px;
    transition: color .3s;
  }

  a:hover {
    color: #666;
  }

  strong {
    font-weight: 800;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  @media (min-width: 576px) {
    html, body {
      font-size: 15px;
    }
  }

  @media (min-width: 768px) {
    html, body {
      font-size: 16px;
    }
  }

  @media (min-width: 992px) {
    html, body {
      font-size: 17px;
    }
  }

  @media (min-width: 1200px) {
    html, body {
      font-size: 19px;
    }
  }
`

const Root = ({ children }) => {
  return (
    <ThemeProvider theme={{ ...styleTheme, awesomegrid: gridConf }}>
      <GlobalProvider>
        <GlobalStyle />
        {children}
      </GlobalProvider>
    </ThemeProvider>
  )
}

export default Root
