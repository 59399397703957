import React, { createContext, useEffect, useState } from "react"

const defaultValues = {
  showImprint: false,
}

export const GlobalContext = createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `s_i`

const loadValue = () => {
  const localStorageValue = isBrowser
    ? localStorage.getItem(localStorageKey)
    : false

  return localStorageValue || false
}

export const GlobalProvider = ({ children }) => {
  const defaultValue = loadValue()

  const [showImprint, setShowImprint] = useState(defaultValue)

  useEffect(() => {
    if (isBrowser) {
      if (!(window?.document?.referrer || "").includes("instagram")) {
        setShowImprint(true)
      }
    }
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        ...defaultValues,
        showImprint,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
